<template>
  <a-row v-if="!loading" class="mt-4">
    <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="trim-top-left">
      <a-card class="h-100">
        <a-row :gutter="[16, 0]">
          <a-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
            id="trim-video-info-box"
          >
            <video-info
              :videoInfo="video"
              :videoMetaData="videoMetaData"
              :totalFrames="totalFrames"
              :isTrimming="true"
            >
            </video-info>
            <a-divider class="my-2" />
            <trim-instruction></trim-instruction>
          </a-col>

          <a-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
            class="trim-video"
          >
            <div v-if="isFixedIntervalShow">
              <a-typography-text>Enter Fixed Trim Interval:</a-typography-text>
              <div class="d-flex my-2">
                <a-input
                  size="small"
                  placeholder="Video Interval in min(s)"
                  type="number"
                  v-model:value="fixedVideoInterval"
                  :disabled="isVideosAlreadyTrimmed"
                  id="fixed-video-interval"
                />
                <a-popconfirm
                  title="This will clear all previous trimming intervals. Do you want to proceed?"
                  ok-text="Yes"
                  cancel-text="No"
                  id="clear-trim-interval-confirm"
                  :disabled="!fixedVideoInterval || !videosToBeTrimmed.length"
                  @confirm="handleConfirmClearTrimming"
                >
                  <a-button
                    class="ml-2"
                    type="primary"
                    @click="handleClickFixIntervalAddBtn"
                    :disabled="isVideosAlreadyTrimmed"
                    id="fix-interval-add-btn"
                  >
                    Add
                  </a-button>
                </a-popconfirm>
              </div>
            </div>
            <!-- <div class="d-flex">
              <a-input
                size="small"
                placeholder="Start frame"
                type="number"
                v-model="startFrame"
                id="trim-video-start-frame"
              />
              <a-input
                size="small"
                placeholder="End frame"
                type="number"
                v-model="endFrame"
                class="ml-2"
                id="trim-video-end-frame"
              />
              <a-button
                class="ml-2"
                type="primary"
                @click="addToPainter"
                :disabled="isDisabled"
                id="trim-video-add-btn"
              >
                Add
              </a-button>
            </div> -->
            <a-list
              size="small"
              bordered
              :data-source="videosToBeTrimmed"
              class="trim-list"
              id="trim-videos-list"
            >
              <template #header>
                <a-typography-text strong
                  >Video(s) to be trimmed</a-typography-text
                >
              </template>
              <template #renderItem="{ item, index }">
                <a-list-item
                  :id="'trim-video-li-' + index"
                  :class="{
                    'list-group-item-primary':
                      isStartTrim && isCurrentItemTrimVideoIndex === index,
                  }"
                >
                  <a-list-item-meta
                    :description="
                      index === 0 &&
                      `(current video -' ${getTrimRangeDuration(item)})`
                    "
                  >
                    <template #title>
                      <span :id="'trim-li-text-' + index"
                        >Video-{{ index + 1 }} ( {{ item.start }} -
                        {{ item.end }} )
                      </span>
                    </template>
                  </a-list-item-meta>
                  <template #actions>
                    <delete-outlined
                      class="text-danger"
                      @click="
                        emitter.emit(
                          'remove-video',
                          videosToBeTrimmed.length - 1 - index
                        )
                      "
                      :id="'trim-interval-delete-' + index"
                    />
                  </template>
                </a-list-item>
              </template>
            </a-list>
            <div class="mt-3 d-flex">
              <a-button
                class="ml-auto"
                type="primary"
                @click="emitter.emit('clear-trimming')"
                id="trim-video-clear-all-btn"
                >Clear All</a-button
              >
              <a-button
                class="ml-3"
                type="primary"
                @click="zoomIn = !zoomIn"
                id="trim-video-zoom-btn"
                >{{ `Zoom ${zoomIn ? 'Out' : 'In'}` }}</a-button
              >
            </div>
          </a-col>
        </a-row>
      </a-card>
    </a-col>
    <a-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
      class="d-flex trim-top-right"
    >
      <video
        ref="video"
        muted
        class="w-100"
        :src="video?.localURL || video?.fileURL"
        id="time-top-right-video"
      ></video>
    </a-col>
  </a-row>

  <a-row style="height: 15vh" class="mt-3" v-if="!loading">
    <a-col :span="24">
      <trim-video
        ref="trimVideo"
        :videoInfo="video"
        :taskId="taskId"
        :taskName="taskName"
        :zoomIn="zoomIn"
        :totalFrames="totalFrames"
        :framesPerPixel="framesPerPixel"
        :videoMetaData="videoMetaData"
        @frameChange="handleFrameChange"
        @onTrim="handleTrim"
        @videosTrimmed="videosTrimmed"
        @changeCurrentVideoStatus="changeCurrentVideoStatus"
      ></trim-video>
    </a-col>
  </a-row>
</template>
<script>
import VideoInfo from '../../annotation/VideoInfo.vue';
import TaskRecordService from 'src/services/taskRecord';
import TrimInstruction from './Instruction.vue';
import VideoPlayer from '../../annotation/Video.vue';
import TrimVideo from './TrimVideo.vue';
import httpClient from '../../../../../service/httpClient';
import getNumberOfFrames from '../../../../shared/Helpers/getNumberOfFrames';
import getTime, {
  getDurationMinutes,
} from '../../../../shared/Helpers/getTime';
import SQSServices from '../../../../../services/sqs';
import { DeleteOutlined } from '@ant-design/icons-vue';
import { evaluateExpression } from 'src/components/shared/Helpers/expressionHelper';

export default {
  inject: ['toast'],
  props: ['video', 'taskId', 'taskName', 'loading'],
  emits: [
    'closeModal',
    'stopLoading',
    'updateTrimProgress',
    'updateTrimLoading',
  ],
  components: {
    VideoInfo,
    VideoPlayer,
    TrimVideo,
    TrimInstruction,
    DeleteOutlined,
  },
  data: function () {
    return {
      currentFrame: 0,
      jobId: null,
      polling: null,
      pollingTimeInSec: 5,
      zoomIn: true,
      totalFrames: Number.MAX_SAFE_INTEGER,
      videoMetaData: null,
      framesPerPixel: 1,
      videosToBeTrimmed: [],
      isStartTrim: false,
      currentTrimVideoIndex: -1,
      startFrame: 0,
      endFrame: 0,
      frameProcessingRate: 30,
      fixedVideoInterval: '',
    };
  },
  mounted() {
    if (this.video) {
      this.getVideoMetaData();
    }
  },
  computed: {
    isLastTrimRemovable() {
      return this.videosToBeTrimmed.length > 0;
    },
    isDisabled() {
      return Number(this.endFrame) < Number(this.startFrame);
    },
    isFixedIntervalShow() {
      if (!this.videoMetaData) return false;
      const videoMinutes = getDurationMinutes(this.videoMetaData?.duration);
      return videoMinutes >= 30;
    },
    isVideosAlreadyTrimmed() {
      if (!this.videosToBeTrimmed.length) return false;
      return this.videoTrimmedWithFixedInterval();
    },
    isCurrentItemTrimVideoIndex() {
      return this.videosToBeTrimmed?.length - 1 - this.currentTrimVideoIndex;
    },
  },

  watch: {
    video(video) {
      if (!video) return;
      this.getVideoMetaData();
    },
  },

  methods: {
    addToPainter() {
      const { startFrame, endFrame } = this;
      this.$refs.trimVideo.addToPainter({
        startFrame: Number(startFrame),
        endFrame: Number(endFrame),
      });
      this.startFrame = this.endFrame;
      this.endFrame = this.endFrame;
    },

    handleFrameChange(frame) {
      this.currentFrame = frame;
      const videoFPS = this.videoMetaData?.frameRate || this.video?.fps;
      const frameToMove = (Number(frame) / videoFPS).toFixed(25);
      this.$refs.video.currentTime = frameToMove;
    },

    async handleTrim(payload) {
      const [error, data] = await SQSServices.sendSQSMessage(payload);
      if (error) {
        console.log({ error });
        this.toast.error(error);
        return;
      }
      this.toast.success(data.Status);

      this.$emit('updateTrimLoading', true);
      this.$emit('closeModal');
    },

    getElapsedTime(intervals) {
      const totalFramsToProcess = intervals.reduce(
        (res, { startFrame, endFrame }) => {
          return res + (endFrame - startFrame) + 1;
        },
        0
      );
      const timeInSeconds = totalFramsToProcess / this.frameProcessingRate;
      return Math.ceil(timeInSeconds / 60);
    },

    changeFramesPerPixel(noOfFrame) {
      this.framesPerPixel = noOfFrame;
    },

    videosTrimmed(videos) {
      this.videosToBeTrimmed = videos.reverse();
    },

    getTrimRangeDuration(video) {
      if (!this.videoMetaData) return '00:00:00';
      const frameDiff = video.end - video.start;
      const seconds = frameDiff / this.videoMetaData?.frameRate;
      const duration = getTime(seconds * 1000);
      return duration;
    },

    changeCurrentVideoStatus(isStartTrim, videoIndex) {
      this.isStartTrim = isStartTrim;
      this.currentTrimVideoIndex = videoIndex;
    },

    removeLastTrim() {
      this.$refs.trimVideo.undoLastTrim();
    },

    async getVideoMetaData() {
      const payload = {id: this.video?.id};
      const [error, res] = await TaskRecordService.getVideoMetaData(
        payload,
        false
      );
      if (error) {
        console.log({ error });
        return resolve(30);
      }

      const { data } = res;
      if (!data) {
        this.setDefaultVideoMetaData();
        return;
      }

      this.setVideoMetaData(data);
      // if (this.isVisualize) this.parseTaskDetails();
      this.$emit('stopLoading');
    },

    setVideoMetaData(data) {
      let {duration, fps , total_frames} = data;

      this.totalFrames = total_frames;

      this.videoMetaData = {
        fps,
        total_frames,
        duration
      };
    },

    setDefaultVideoMetaData() {
      if (!this.video) return;
      const { fps, duration } = this.video;
      this.totalFrames = getNumberOfFrames(duration, fps);
    },

    addVideoTrimIntervals() {
      if (!this.fixedVideoInterval) return;
      if (!this.videoMetaData) return;
      const seconds = Number(this.fixedVideoInterval) * 60;
      const intervalFrames = seconds * this.videoMetaData?.frameRate;
      for (let i = 0; i < this.totalFrames; i += intervalFrames + 1) {
        let video = {};
        video['startFrame'] = i;
        video['endFrame'] = i + intervalFrames;
        if (this.totalFrames - video['endFrame'] < intervalFrames) {
          video['endFrame'] = this.totalFrames;
          i = this.totalFrames;
        }
        console.log('video->', video);
        this.$refs.trimVideo.addToPainter(video);
      }
      this.fixedVideoInterval = '';
    },

    videoTrimmedWithFixedInterval() {
      const trimmedIntervals = [...this.videosToBeTrimmed].reverse();
      let trimmedIntervalFrames =
        trimmedIntervals[0].end - trimmedIntervals[0].start;
      let intervalCounts = 0;
      for (let i = 0; i < this.totalFrames; i += trimmedIntervalFrames + 1) {
        intervalCounts += 1;
      }
      const trimEqually = trimmedIntervals
        .slice(0, -1)
        ?.every(
          (interval) => interval.end - interval.start == trimmedIntervalFrames
        );
      const res =
        intervalCounts === this.videosToBeTrimmed.length && trimEqually;
      return res;
    },
    handleClickFixIntervalAddBtn() {
      if (this.videosToBeTrimmed.length) return;
      this.addVideoTrimIntervals();
    },
    handleConfirmClearTrimming() {
      this.emitter.emit('clear-trimming');
      this.addVideoTrimIntervals();
    },
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },
};
</script>
<style scoped>
.progress {
  margin-top: 1.5em;
  height: 20px;
  background: lightgray;
}

.progress-bar {
  font-weight: bold;
}

.trimming-details {
  margin-top: 0.5em;
  overflow-y: scroll;
  /* height: 200px; */
  height: 160px;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
}

.trim-list {
  height: 50vh;
  margin-top: 1em;
  overflow: auto;
}

.current-video {
  font-weight: bold;
}

li {
  font-size: small;
}

.input-number {
  width: 40%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

video {
  object-fit: cover;
}

.trim-top-left {
  max-height: 590px;
}

.trim-top-right {
  height: 530px;
}

@media only screen and (max-width: 992px) {
  .trim-top-left {
    height: 510px;
  }

  .trim-top-right {
    height: 300px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .trim-top-left {
    height: 800px;
    overflow-y: auto;
  }

  .trim-video {
    height: 350px;
    overflow-y: auto;
    margin-top: 8px;
  }
}
</style>
